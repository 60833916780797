// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSupportBadgeSilverMuted14 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={17} height={20} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      opacity={0.5}
      d="m8.316 1.523-.15-.047-.149.047-7 2.196-.356.112.006.373c.053 3.3.453 5.782 1.562 7.985 1.108 2.201 2.898 4.072 5.63 6.205l.314.245.309-.25c2.476-2.008 4.183-3.786 5.314-5.966 1.13-2.18 1.661-4.716 1.87-8.197l.023-.39-.373-.117-7-2.196Z"
      fill="url(#paint0_linear_192_1421)"
      stroke="url(#paint1_linear_192_1421)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_192_1421"
        x1={8.167}
        y1={1}
        x2={8.292}
        y2={18.444}
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.319} stopColor="#BABABA" />
        <stop offset={0.986} stopColor="#383838" />
      </linearGradient>
      <linearGradient id="paint1_linear_192_1421" x1={8.167} y1={2} x2={8.167} y2={18} gradientUnits="userSpaceOnUse">
        <stop stopColor="#AEAEAE" />
        <stop offset={1} stopColor="#575757" />
      </linearGradient>
    </defs>
  </svg>
))
SvgSupportBadgeSilverMuted14.displayName = 'SvgSupportBadgeSilverMuted14'
const Memo = memo(SvgSupportBadgeSilverMuted14)
export { Memo as SvgSupportBadgeSilverMuted14 }
