// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSupportBadgeDiamond14 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={17} height={20} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="m8.483 1.523-.15-.047-.15.047-7 2.196-.356.112.006.373c.054 3.3.454 5.782 1.562 7.985 1.108 2.201 2.898 4.072 5.63 6.205l.314.245.31-.25c2.476-2.008 4.183-3.786 5.314-5.966 1.13-2.18 1.66-4.716 1.87-8.197l.023-.39-.373-.117-7-2.196Z"
      fill="url(#paint0_linear_193_971)"
      stroke="url(#paint1_linear_193_971)"
    />
    <defs>
      <linearGradient id="paint0_linear_193_971" x1={8.333} y1={2} x2={8.333} y2={18} gradientUnits="userSpaceOnUse">
        <stop stopColor="#93F4FA" />
        <stop offset={1} stopColor="#06EFFF" />
      </linearGradient>
      <linearGradient id="paint1_linear_193_971" x1={8.333} y1={2} x2={8.333} y2={18} gradientUnits="userSpaceOnUse">
        <stop stopColor="#AAF3F8" />
        <stop offset={1} stopColor="#03B2BE" />
      </linearGradient>
    </defs>
  </svg>
))
SvgSupportBadgeDiamond14.displayName = 'SvgSupportBadgeDiamond14'
const Memo = memo(SvgSupportBadgeDiamond14)
export { Memo as SvgSupportBadgeDiamond14 }
