// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSupportBadgeDiamond10 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={16} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M7.156 1.525 7 1.474l-.156.051-5 1.647-.35.115.006.368c.038 2.481.324 4.36 1.127 6.034.802 1.674 2.097 3.092 4.056 4.698l.323.264.318-.27c1.776-1.511 3.01-2.859 3.828-4.515.818-1.655 1.198-3.574 1.347-6.19l.022-.384-.365-.12-5-1.647Z"
      fill="url(#paint0_linear_102_9791)"
      stroke="url(#paint1_linear_102_9791)"
    />
    <defs>
      <linearGradient id="paint0_linear_102_9791" x1={7} y1={2} x2={7} y2={14} gradientUnits="userSpaceOnUse">
        <stop stopColor="#93F4FA" />
        <stop offset={1} stopColor="#06EFFF" />
      </linearGradient>
      <linearGradient id="paint1_linear_102_9791" x1={7} y1={2} x2={7} y2={14} gradientUnits="userSpaceOnUse">
        <stop stopColor="#AAF3F8" />
        <stop offset={1} stopColor="#03B2BE" />
      </linearGradient>
    </defs>
  </svg>
))
SvgSupportBadgeDiamond10.displayName = 'SvgSupportBadgeDiamond10'
const Memo = memo(SvgSupportBadgeDiamond10)
export { Memo as SvgSupportBadgeDiamond10 }
