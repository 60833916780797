// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSupportBadgeDiamondMuted14 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={17} height={20} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      opacity={0.5}
      d="m8.483 1.523-.15-.047-.15.047-7 2.196-.355.112.006.373c.053 3.3.453 5.782 1.562 7.985 1.107 2.201 2.897 4.072 5.63 6.205l.313.245.31-.25c2.476-2.008 4.183-3.786 5.314-5.966 1.13-2.18 1.661-4.716 1.87-8.197l.023-.39-.373-.117-7-2.196Z"
      fill="url(#paint0_linear_193_1429)"
      stroke="url(#paint1_linear_193_1429)"
    />
    <defs>
      <linearGradient id="paint0_linear_193_1429" x1={8.334} y1={2} x2={8.334} y2={18} gradientUnits="userSpaceOnUse">
        <stop stopColor="#93F4FA" />
        <stop offset={1} stopColor="#06EFFF" />
      </linearGradient>
      <linearGradient id="paint1_linear_193_1429" x1={8.334} y1={2} x2={8.334} y2={18} gradientUnits="userSpaceOnUse">
        <stop stopColor="#AAF3F8" />
        <stop offset={1} stopColor="#03B2BE" />
      </linearGradient>
    </defs>
  </svg>
))
SvgSupportBadgeDiamondMuted14.displayName = 'SvgSupportBadgeDiamondMuted14'
const Memo = memo(SvgSupportBadgeDiamondMuted14)
export { Memo as SvgSupportBadgeDiamondMuted14 }
