// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSupportBadgeGold10 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={14} height={16} viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      d="M7.156 1.525 7 1.474l-.156.051-5 1.647-.35.115.006.368c.038 2.481.324 4.36 1.127 6.034.802 1.674 2.097 3.092 4.056 4.698l.323.264.318-.27c1.776-1.511 3.01-2.859 3.828-4.515.818-1.655 1.198-3.574 1.347-6.19l.022-.384-.365-.12-5-1.647Z"
      fill="url(#paint0_linear_102_9655)"
      stroke="url(#paint1_linear_102_9655)"
    />
    <defs>
      <linearGradient id="paint0_linear_102_9655" x1={7} y1={2} x2={7} y2={14} gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5D28F" />
        <stop offset={1} stopColor="#554920" />
      </linearGradient>
      <linearGradient id="paint1_linear_102_9655" x1={7} y1={2} x2={7} y2={14} gradientUnits="userSpaceOnUse">
        <stop stopColor="#D5C48A" />
        <stop offset={1} stopColor="#3F371A" />
      </linearGradient>
    </defs>
  </svg>
))
SvgSupportBadgeGold10.displayName = 'SvgSupportBadgeGold10'
const Memo = memo(SvgSupportBadgeGold10)
export { Memo as SvgSupportBadgeGold10 }
