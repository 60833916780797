// THIS FILE WAS AUTOGENERATED BY SVGR. DO NOT MODIFY IT MANUALLY;
import { Ref, SVGProps, forwardRef, memo } from 'react'

const SvgSupportBadgeGoldMuted14 = forwardRef((props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg width={18} height={20} viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
    <path
      opacity={0.5}
      d="M9.15 1.523 9 1.476l-.15.047-7 2.196-.356.112.006.373c.054 3.3.453 5.782 1.562 7.985 1.108 2.201 2.898 4.072 5.63 6.205l.314.245.309-.25c2.476-2.008 4.184-3.786 5.315-5.966 1.13-2.18 1.66-4.716 1.87-8.197l.023-.39-.373-.117-7-2.196Z"
      fill="url(#paint0_linear_190_12886)"
      stroke="url(#paint1_linear_190_12886)"
    />
    <defs>
      <linearGradient id="paint0_linear_190_12886" x1={9} y1={2} x2={9} y2={18} gradientUnits="userSpaceOnUse">
        <stop stopColor="#E5D28F" />
        <stop offset={1} stopColor="#554920" />
      </linearGradient>
      <linearGradient id="paint1_linear_190_12886" x1={9} y1={2} x2={9} y2={18} gradientUnits="userSpaceOnUse">
        <stop stopColor="#D5C48A" />
        <stop offset={1} stopColor="#3F371A" />
      </linearGradient>
    </defs>
  </svg>
))
SvgSupportBadgeGoldMuted14.displayName = 'SvgSupportBadgeGoldMuted14'
const Memo = memo(SvgSupportBadgeGoldMuted14)
export { Memo as SvgSupportBadgeGoldMuted14 }
